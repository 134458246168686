/* eslint-disable jsx-a11y/no-distracting-elements */
function App() {
    return (
        <>
            <main class="main">
                <header class="navbar navbar-sticky sticky-bg-color--dark navbar-expand-lg navbar-dark">
                    <div class="container-fluid position-relative">
                        <img src="./assets/portall.png" width="155" alt="" />
                        <button
                            class="navbar-toggler d-lg-none"
                            type="button"
                            data-toggle="navbarToggler"
                            aria-label="Toggle navigation"
                        >
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="navbar-inner">
                            <button
                                class="navbar-toggler d-lg-none"
                                type="button"
                                data-toggle="navbarToggler"
                                aria-label="Toggle navigation"
                            >
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <nav>
                                <ul class="navbar-nav" id="navbar-nav">
                                    <li class="nav-item">
                                        <a class="nav-link" href="/#introduce">
                                            Introduce
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="/#tokenomics">
                                            Tokenomics
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="/#roadmap">
                                            Roadmap
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="/#partners">
                                            Partners
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="d-flex align-items-center ml-lg-1 ml-xl-2 mr-5 mr-sm-6 m-lg-0">
                            <a
                                href="/#presale"
                                class="btn btn-size--sm btn-bg--cta--1 rounded--full btn-hover--3d btn-hover--splash smooth-scroll"
                            >
                                <span class="btn__text d-flex align-items-center">
                                    <i class="fa fa-gem color--white pr-1 h6-font font-w--700"></i>
                                    Pre-Sale
                                </span>
                            </a>
                            <div class="dropdown-module dropdown-module--dark d-inline-block position-relative ml-1 ml-sm-2">
                                <button
                                    class="dropdown-module__toggler"
                                    type="button"
                                    fdprocessedid="dgev3r"
                                >
                                    <span class="flag-image">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="48"
                                            height="48"
                                            viewBox="0 0 48 48"
                                            class="svg replaced-svg"
                                        >
                                            <g class="nc-icon-wrapper">
                                                <path
                                                    fill="#002781"
                                                    d="M46,6H2C0.896,6,0,6.896,0,8v32c0,1.104,0.896,2,2,2h44c1.104,0,2-0.896,2-2V8C48,6.896,47.104,6,46,6z"
                                                ></path>
                                                <path
                                                    fill="#E6E6E6"
                                                    d="M48,8c0-1.104-0.896-2-2-2h-5.161L28,15.876V6h-8v9.876L7.161,6H2C0.896,6,0,6.896,0,8v2.586L12.239,20H0v8 h12.239L0,37.415V40c0,1.104,0.896,2,2,2h5.161L20,32.124V42h8v-9.876L40.839,42H46c1.104,0,2-0.896,2-2v-2.585L35.761,28H48v-8 H35.761L48,10.586V8z"
                                                ></path>
                                                <polygon
                                                    fill="#D10D24"
                                                    points="48,22 26,22 26,6 22,6 22,22 0,22 0,26 22,26 22,42 26,42 26,26 48,26 "
                                                ></polygon>
                                                <path
                                                    fill="#D10D24"
                                                    d="M47.001,6.307L29.2,20h3.28L48,8.062V8C48,7.268,47.587,6.656,47.001,6.307z"
                                                ></path>
                                                <path
                                                    fill="#D10D24"
                                                    d="M32.48,28H29.2l17.801,13.693C47.587,41.344,48,40.732,48,40v-0.062L32.48,28z"
                                                ></path>
                                                <path
                                                    fill="#D10D24"
                                                    d="M15.52,28L0,39.938V40c0,0.732,0.413,1.344,0.999,1.693L18.8,28H15.52z"
                                                ></path>
                                                <path
                                                    fill="#D10D24"
                                                    d="M15.52,20h3.28L0.999,6.307C0.413,6.656,0,7.268,0,8v0.062L15.52,20z"
                                                ></path>
                                            </g>
                                        </svg>
                                    </span>
                                </button>
                                <ul
                                    class="dropdown-module-list"
                                    style={{ display: "none" }}
                                >
                                    <li>
                                        <a
                                            class="dropdown-module__item d-flex align-items-center"
                                            data-lang="en"
                                            href="javascript.html"
                                        >
                                            <span class="flag-image mr-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="48"
                                                    height="48"
                                                    viewBox="0 0 48 48"
                                                    class="svg replaced-svg"
                                                >
                                                    <g class="nc-icon-wrapper">
                                                        <path
                                                            fill="#002781"
                                                            d="M46,6H2C0.896,6,0,6.896,0,8v32c0,1.104,0.896,2,2,2h44c1.104,0,2-0.896,2-2V8C48,6.896,47.104,6,46,6z"
                                                        ></path>
                                                        <path
                                                            fill="#E6E6E6"
                                                            d="M48,8c0-1.104-0.896-2-2-2h-5.161L28,15.876V6h-8v9.876L7.161,6H2C0.896,6,0,6.896,0,8v2.586L12.239,20H0v8 h12.239L0,37.415V40c0,1.104,0.896,2,2,2h5.161L20,32.124V42h8v-9.876L40.839,42H46c1.104,0,2-0.896,2-2v-2.585L35.761,28H48v-8 H35.761L48,10.586V8z"
                                                        ></path>
                                                        <polygon
                                                            fill="#D10D24"
                                                            points="48,22 26,22 26,6 22,6 22,22 0,22 0,26 22,26 22,42 26,42 26,26 48,26 "
                                                        ></polygon>
                                                        <path
                                                            fill="#D10D24"
                                                            d="M47.001,6.307L29.2,20h3.28L48,8.062V8C48,7.268,47.587,6.656,47.001,6.307z"
                                                        ></path>
                                                        <path
                                                            fill="#D10D24"
                                                            d="M32.48,28H29.2l17.801,13.693C47.587,41.344,48,40.732,48,40v-0.062L32.48,28z"
                                                        ></path>
                                                        <path
                                                            fill="#D10D24"
                                                            d="M15.52,28L0,39.938V40c0,0.732,0.413,1.344,0.999,1.693L18.8,28H15.52z"
                                                        ></path>
                                                        <path
                                                            fill="#D10D24"
                                                            d="M15.52,20h3.28L0.999,6.307C0.413,6.656,0,7.268,0,8v0.062L15.52,20z"
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </span>
                                            English
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            class="dropdown-module__item d-flex align-items-center"
                                            data-lang="zh"
                                            href="javascript.html"
                                        >
                                            <span class="flag-image mr-2">
                                                <img
                                                    src="./assets/flag-cn.png"
                                                    alt="flag"
                                                />
                                            </span>
                                            中文
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <a class="btn--splash smooth-scroll" href="/#">
                                <span
                                    class="d-flex align-items-left"
                                    style={{ color: "rgb(29, 29, 61)" }}
                                ></span>
                            </a>
                            <a class="smooth-scroll" href="/#">
                                <span
                                    class="d-flex align-items-left"
                                    style={{ color: "rgb(29, 29, 61)" }}
                                >
                                    m
                                </span>
                            </a>
                        </div>
                    </div>
                </header>
                <section
                    id="top"
                    class="hero hero--v3 ico-progress ico-progress--v2 section--dark bg-color--dark--1 d-flex align-items-center hidden"
                >
                    <div
                        class="background-holder background--move z-index1"
                        style={{
                            backgroundImage:
                                "url('images/layout-bg-overlay-06.svg')",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <img
                            src="./assets/layout-bg-overlay-06.svg"
                            alt=""
                            class="background-image-holder"
                            style={{ display: "none" }}
                        />
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-10 col-lg-8 px-lg-0 mx-auto mb-3 mb-lg-0 mt-8 mt-lg-0 text-center z-index2">
                                <div class="hero-content">
                                    <img
                                        src="./assets/portall.png"
                                        width="240"
                                        alt=""
                                    />
                                    <br />
                                    <br />
                                    <p class="hero__description mb-3 mb-lg-5 mb-md-6">
                                        Welcome! Portal aims to unite games and
                                        gamers from various blockchain networks
                                        on its cross-chain token platform,
                                        establishing a united Web3 gaming
                                        ecosystem. Portal enables a seamless
                                        multi-chain experience for gamers,
                                        connecting siloed Web3 games with new
                                        players through a sleek user experience.
                                    </p>

                                    <a
                                        href="/#presale"
                                        class="btn btn-bg--cta--1 rounded--full btn-hover--3d btn-hover--splash mb-2 mx-1 smooth-scroll"
                                    >
                                        <i class="fa fa-gem color--white pr-1 h6-font font-w--700"></i>
                                        <span class="btn__text">Pre-Sale</span>
                                    </a>
                                    <a
                                        href="/#airdrop"
                                        class="btn btn-bg--primary rounded--full btn-hover--3d btn-hover--splash mb-2 mx-1 smooth-scroll"
                                    >
                                        <i class="fa fa-parachute-box color--white pr-1 h6-font font-w--700"></i>
                                        <span class="btn__text">Airdrop</span>
                                    </a>

                                    <marquee
                                        style={{ color: "#ffac02" }}
                                        width="100%"
                                        direction="left"
                                        height="100px"
                                    >
                                        <b>Binance</b> will list
                                        <b>
                                            Portal (PORTAL) at 2024-02-29 10:00
                                            (UTC)
                                        </b>
                                    </marquee>
                                </div>
                            </div>

                            <div class="col-12 col-md-1 mr-md-3 text-center icon-group--sticky">
                                <ul class="icon-group icon-group--vertical rounded--full rounded--full justify-content-center">
                                    <li>
                                        <a
                                            href="https://t.me/portaldropnet"
                                            target="_blank"
                                            class="color--primary"
                                            rel="noreferrer"
                                        >
                                            <i class="fab fa-telegram-plane"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 col-md-10 mx-auto text-md-center">
                                <div class="bg-color--dark--2 rounded--10 pr-lg-3 d-md-inline-block d-lg-block">
                                    <div class="pricing-info d-md-flex align-items-center justify-content-between justify-content-md-start">
                                        <div class="d-md-flex align-items-center justify-content-md-center justify-content-lg-start text-center text-md-left mb-md-0">
                                            <div class="pricing-info-item pricing-info-item--active px-md-2 mr-xl-4">
                                                <span class="text-color--400 font-size--20">
                                                    Current Price
                                                </span>
                                                <p class="font-size--20 font-w--700 mt-md-2">
                                                    1
                                                    <span class="font-w--500">
                                                        ETH
                                                    </span>
                                                    = 150,000
                                                    <span class="font-w--500">
                                                        PORTAL
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="pricing-info-item py-1"></div>
                                        </div>
                                        <div class="text-center text-md-left pb-2 pb-lg-0 px-3">
                                            <span class="text-color--400 font-size--20 mt-2">
                                                Airdrop ending
                                            </span>
                                            <ul class="d-flex justify-content-center justify-content-lg-start text-left px-0">
                                                <li class="d-flex flex-column mr-2 mr-sm-3 count_down_days">
                                                    <span class="number color--white opacity--80 font-size--40 font-w--600">
                                                        03
                                                    </span>
                                                    <span class="text-color--400">
                                                        Days
                                                    </span>
                                                </li>
                                                <li class="d-flex flex-column mr-2 mr-sm-3 count_down_hours">
                                                    <span class="number color--white opacity--80 font-size--40 font-w--600">
                                                        22
                                                    </span>
                                                    <span class="text-color--400">
                                                        Hours
                                                    </span>
                                                </li>
                                                <li class="d-flex flex-column mr-2 mr-sm-3 count_down_minutes">
                                                    <span class="number color--white opacity--80 font-size--40 font-w--600">
                                                        18
                                                    </span>
                                                    <span class="text-color--400">
                                                        Minutes
                                                    </span>
                                                </li>
                                                <li class="d-flex flex-column count_down_seconds">
                                                    <span class="number color--white opacity--80 font-size--40 font-w--600">
                                                        44
                                                    </span>
                                                    <span class="text-color--400">
                                                        Seconds
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    class="space--top pb-5 pb-lg-10 about about--v3 section--dark bg-color--dark--1 border--top"
                    id="about"
                >
                    <div class="container">
                        <div
                            id="introduce"
                            class="row align-items-center flex-column-reverse flex-lg-row text-center text-lg-left mb-10"
                        >
                            <div class="col-12 col-lg-7 col-xl-6">
                                <div class="text-center text-lg-left reveal">
                                    <h2 class="h3-font mb-3">
                                        How Does PORTAL Work?
                                    </h2>
                                    <p class="mb-3">
                                        The Portal Platform is being developed
                                        by the Portal Foundation. The Portal
                                        Foundation is an organisation based in
                                        the Cayman Islands that is tasked with
                                        developing and nurturing the Portal
                                        ecosystem. The Portal Foundation's
                                        mission is to support and promote the
                                        Portal ecosystem by enabling gamers,
                                        game developers, and decentralised
                                        governance. Portal's platform offers
                                        players a unified access point for Web3
                                        gaming and game-related transactions,
                                        removing the complexity of interacting
                                        withheterogeneous on-chain gaming
                                        environments. Portal's technology
                                        delivers gamer access and game
                                        distribution for a vast array of titles,
                                        paving the way for a smooth transition
                                        for Web2 gamers into the Web3 universe.
                                    </p>
                                </div>
                                <div class="text-center"></div>
                            </div>
                            <div class="col-12 col-lg-5 col-xl-6 mb-6 mb-lg-0 text-center">
                                <picture class="about__image reveal w-75">
                                    <video
                                        class="img-fluid w-100"
                                        loop={true}
                                        autoPlay={true}
                                        muted={true}
                                        src="https://portal-drop.net/media/loading.mp4"
                                    >
                                        &lt;
                                    </video>
                                </picture>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="space section--dark bg-color--dark--2">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-9 mx-auto mx-md-0">
                                <div class="mb-5 mb-lg-7 text-center text-md-left reveal">
                                    <h2 class="h3-font mb-2">
                                        How to claim PORTAL?
                                    </h2>
                                    <p>
                                        Portal is the governance token of
                                        PORTAL, with a total issuance of only
                                        1,000,000,000. Among them, 50,000,000
                                        PORTAL tokens for community airdrop, and
                                        distributed to community users for free.
                                        150,000,000 PORTAL tokens for presale,
                                        and the presale price is 1 ETH = 150,000
                                        PORTAL. After the presale is completed,
                                        PORTAL will be listed on Uniswap,
                                        Hotbit, Binance and Coinbase exchanges
                                        immediately. PORTAL tokens can now be
                                        obtained by participating in PORTAL
                                        airdrop or presale. After presale is
                                        completed, you can buy PORTAL through
                                        exchanges.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="position-relative remove-space--bottom">
                            <div class="vertical-border d-flex pos-abs-center h-100 w-100">
                                <span class="column-border"></span>
                            </div>
                            <div class="row horizontal-border justify-content-between mb-lg-5 pb-lg-5 reveal">
                                <div class="col-12 col-md-6">
                                    <div class="d-lg-flex mb-4 mb-lg-0 text-center text-md-left">
                                        <span class="mb-3 mb-lg-0 mr-md-3">
                                            <img
                                                src="./assets/img-icon-decentralized.svg"
                                                alt="icon"
                                            />
                                        </span>
                                        <div>
                                            <h3 class="h5-font font-w--600 text-color--200 mb-2">
                                                Airdrop Rules
                                            </h3>
                                            <p>
                                                ● The amount for airdrop is
                                                50,000,000 PORTAL tokens.
                                            </p>
                                            <p>
                                                ● Send an ETH wallet address to
                                                participate in airdrop. Every
                                                participant can get 100 PORTAL
                                                tokens by participating in
                                                airdrop. Copy and share your
                                                referral link to your friends,
                                                you will be rewarded 100 PORTAL
                                                tokens for per each referral.
                                            </p>
                                            <p>
                                                ● Each person can invite
                                                unlimited people.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="d-lg-flex mb-4 mb-lg-0 text-center text-md-left">
                                        <span class="mb-3 mb-lg-0 mr-md-3">
                                            <img
                                                src="./assets/img-icon-p2p.svg"
                                                alt="icon"
                                            />
                                        </span>
                                        <div>
                                            <h3 class="h5-font font-w--600 text-color--200 mb-2">
                                                Airdrop Details
                                            </h3>
                                            <p>
                                                ● The PORTAL airdrop will end on
                                                2024-02-29. After the airdrop
                                                ends, PORTAL tokens will be
                                                automatically distributed to
                                                your submitted ETH wallet
                                                address.
                                            </p>
                                            <p>
                                                ● If the presale is completed in
                                                advance, the airdrop
                                                distribution and PORTAL listing
                                                will be advanced.
                                            </p>
                                            <p>
                                                ● Please do real referral. If
                                                our system discovered unwanted
                                                activities known as cheating,
                                                the reward will be removed
                                                during distribution.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row horizontal-border justify-content-between mb-lg-5 pb-lg-5 reveal">
                                <div class="col-12 col-md-6">
                                    <div class="d-lg-flex mb-4 mb-lg-0 text-center text-md-left">
                                        <span class="mb-3 mb-lg-0 mr-md-3">
                                            <img
                                                src="./assets/img-icon-wallet.svg"
                                                alt="icon"
                                            />
                                        </span>
                                        <div>
                                            <h3 class="h5-font font-w--600 text-color--200 mb-2">
                                                Pre-sale Rules
                                            </h3>
                                            <p>
                                                ● The amount for presale is
                                                150,000,000 PORTAL tokens.
                                            </p>
                                            <p>
                                                ● Pre-sale time: 2024-02-29
                                                10:00 (UTC).
                                            </p>
                                            <p>
                                                ● Pre-sale price 1 ETH = 150,000
                                                PORTAL. Mini buy 0.02 ETH, Max
                                                buy 50 ETH.
                                            </p>
                                            <p>
                                                ● Use your wallet to send ETH to
                                                the presale address provided on
                                                this platform. Our system will
                                                automatically send PORTAL tokens
                                                to your wallet instantly. You
                                                can send ETH any amount.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="d-lg-flex mb-4 mb-lg-0 text-center text-md-left">
                                        <span class="mb-3 mb-lg-0 mr-md-3">
                                            <img
                                                src="./assets/img-icon-ledger.svg"
                                                alt="icon"
                                            />
                                        </span>
                                        <div>
                                            <h3 class="h5-font font-w--600 text-color--200 mb-2">
                                                Pre-sale Details
                                            </h3>
                                            <p>
                                                ● The distribution of PORTAL
                                                will be based on ETH time
                                                arrived. First come, first
                                                served.
                                            </p>
                                            <p>
                                                ● Once the presale is over. 30%
                                                of the funds raised will be
                                                locked in Uniswap liquidity.
                                                Similarly, 30% of the funds
                                                raised will be used for listing
                                                on Binance, Coinbase, Huobi and
                                                Hotbit exchanges. The other 40%
                                                of the funds raised will be used
                                                to buyback PORTAL tokens after
                                                Pre-sale. This action will
                                                prevent people from dumping.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="space section--dark bg-color--dark--1 token-sale-details token-sale-details--v1">
                    <div class="container">
                        <div id="presale" class="row">
                            <div class="col-12 col-sm-10 col-md-8 mx-auto reveal">
                                <div class="text-center mb-5 mb-lg-7 reveal">
                                    <h2 class="h3-font mb-2">
                                        Pre-sale is live
                                    </h2>
                                    <div class="card bg-color--black-opacity--15 border--none p-2 w-100 mb-2">
                                        <span class="body-font font-w--500 mb-1">
                                            Pre-Sale Address
                                        </span>
                                        <span class="color--primary h6-font font-w--700">
                                            0x133a1D9Ad26B680b639f2639BFe069696e7eeBf1
                                        </span>
                                        <div class="mt-2">
                                            <button
                                                text="0x133a1D9Ad26B680b639f2639BFe069696e7eeBf1"
                                                class="copy btn btn-size--sm btn-bg--primary rounded--full btn-hover--splash btn-hover--3d"
                                            >
                                                <span class="btn__text">
                                                    Copy Address
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <p>
                                        ● Pre-sale quantity: 150,000,000 PORTAL
                                        (15% of total supply).
                                    </p>
                                    <p>
                                        ● Pre-sale price: 1 ETH = 150,000
                                        PORTAL.
                                    </p>
                                    <p>
                                        Mini buy is 0.02 ETH, and max buy is 50
                                        ETH.
                                    </p>
                                    <p>
                                        Send ETH from your wallet to the presale
                                        address above. Our system will send
                                        PORTAL tokens to your wallet
                                        immediately.
                                        <br />
                                        You can also send from exchange
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-lg-4 mb-3 reveal">
                                <div class="card bg-color--black-opacity--15 border--none p-2 p-md-4 w-100">
                                    <span class="body-font font-w--500 mb-1">
                                        Token Name
                                    </span>
                                    <span class="color--primary h4-font font-w--700">
                                        Portal
                                    </span>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4 mb-3 reveal">
                                <div class="card bg-color--black-opacity--15 border--none p-2 p-md-4 w-100 h-100">
                                    <span class="body-font font-w--500 mb-1">
                                        Token Symbol
                                    </span>
                                    <span class="color--primary h4-font font-w--700">
                                        PORTAL
                                    </span>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4 mb-3 reveal">
                                <div class="card bg-color--black-opacity--15 border--none p-2 p-md-4 w-100 h-100">
                                    <span class="body-font font-w--500 mb-1">
                                        Decimals
                                    </span>
                                    <span class="color--primary h4-font font-w--700">
                                        18
                                    </span>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4 mb-3 reveal">
                                <div class="card bg-color--black-opacity--15 border--none p-2 p-md-4 w-100 h-100">
                                    <span class="body-font font-w--500 mb-1">
                                        Contract Address
                                    </span>
                                    <span class="color--primary font-w--700">
                                        0xe671dcd8d7427335f83bab9c579805ef35a1c646
                                        <a
                                            href="https://etherscan.io/token/0xe671dcd8d7427335f83bab9c579805ef35a1c646"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i class="fa fa-external-link-alt text-secondary"></i>
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4 mb-3 reveal">
                                <div class="card bg-color--black-opacity--15 border--none p-2 p-md-4 w-100 h-100">
                                    <span class="body-font font-w--500 mb-1">
                                        Blockchain
                                    </span>
                                    <span class="color--primary h4-font font-w--700">
                                        ETH (ERC-20)
                                    </span>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4 mb-3 reveal">
                                <div class="card bg-color--black-opacity--15 border--none p-2 p-md-4 w-100 h-100">
                                    <span class="body-font font-w--500 mb-1">
                                        Total Supply
                                    </span>
                                    <span class="color--primary h4-font font-w--700">
                                        1,000,000,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div
                            id="airdrop"
                            class="bonuses bonuses--v1 bg-color--black-opacity--10 rounded--05 mt-1 mt-md-2"
                        >
                            <div class="row">
                                <div class="col-12">
                                    <div class="border--bottom pt-4 pb-2 text-center">
                                        <h4 class="font-size--30 font-w--600">
                                            PORTAL Airdrop is live
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row py-6 justify-content-center">
                                <div class="col-sm-12 col-md-7 px-0">
                                    <form
                                        action="/register.php"
                                        id="airdrop-form"
                                        method="post"
                                        data-lpignore="true"
                                    >
                                        <center>
                                            <div id="response_div_div"></div>
                                            <div id="login-form">
                                                <div class="domains-search">
                                                    <input
                                                        type="text"
                                                        style={{
                                                            color: "#e8e8e8",
                                                            width: "70%",
                                                            backgroundColor:
                                                                "#1e202d",
                                                        }}
                                                        maxlength="42"
                                                        minlength="12"
                                                        class="form-control"
                                                        id="wallet_address"
                                                        name="wallet_address"
                                                        placeholder="Enter your ERC20 wallet address"
                                                    />
                                                </div>
                                                <div id="form-group">
                                                    <br />
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "#fdd700",
                                                            color: "#000",
                                                            border: "none",
                                                            height: "42px",
                                                        }}
                                                        id="btn-submit"
                                                        class="btn btn-default-yellow-fill search initial-transform"
                                                        type="submit"
                                                        name="register"
                                                    >
                                                        Submit
                                                    </button>
                                                    <br />
                                                    <br />
                                                    <div id="response_div"></div>
                                                </div>

                                                <div class="special-note">
                                                    <span class="text">
                                                        How to get your wallet
                                                        address?
                                                    </span>
                                                </div>
                                                <div class="special-note">
                                                    <span class="text">
                                                        <b
                                                            class=""
                                                            style={{
                                                                color: "#fdd700",
                                                            }}
                                                        >
                                                            Open Binance &gt;
                                                            Click Deposit &gt;
                                                            Search PORTAL &gt;
                                                            Copy Ethereum
                                                            (ERC20) Address &gt;
                                                            Paste Here
                                                            <br />
                                                            Or you can get it
                                                            from
                                                            <a
                                                                href="https://community.trustwallet.com/t/how-to-get-bep20-address/70844"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                here
                                                            </a>
                                                        </b>
                                                    </span>
                                                </div>
                                            </div>
                                        </center>
                                    </form>
                                </div>

                                <div class="container"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    id="tokenomics"
                    class="space token-saleinfo token-saleinfo--v1 section--dark bg-color--dark--2"
                >
                    <div
                        class="background-holder background--cover background--top opacity--08"
                        style={{
                            backgroundImage: "url('images/layout-sphere.svg')",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <img
                            src="./assets/layout-sphere.svg"
                            alt=""
                            class="background-image-holder"
                            style={{ display: "none" }}
                        />
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-10 col-md-8 mx-auto reveal">
                                <div class="text-center mb-5 mb-lg-7">
                                    <h2 class="h3-font mb-2">
                                        PORTAL Tokenomics
                                    </h2>
                                    <p>
                                        A total of 1,000,000,000 PORTAL tokens
                                        that will be distributed. Here's a
                                        breakdown of the PORTAL token:
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 col-lg-8 pr-lg-6 mb-4 mb-lg-0">
                                <div class="token-saleinfo-dist d-md-flex align-items-md-center reveal">
                                    <div
                                        id="pie-chart--v1"
                                        class="pie-chart--flat position-relative mx-auto mr-md-3 mb-3 mb-md-0"
                                    >
                                        <div class="text-center color--primary d-flex flex-column pos-abs-center">
                                            <span class="font-size--14 font-w--600 mb-1">
                                                Total Token Supply
                                            </span>
                                            <span class="font-w--600 mb-1">
                                                1,000,000,000
                                            </span>
                                            <span class="font-w--600">
                                                PORTAL
                                            </span>
                                        </div>
                                        <svg
                                            width="280"
                                            height="280"
                                            viewBox="0 0 280 280"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xlink="http://www.w3.org/1999/xlink"
                                        >
                                            <path
                                                d="M 140.11148573950265 0.00004438954314878174 A 140 140 0 1 1 140.0995427377561 0.000035388421053994534 Z M 140.06221421109757 52.50002211776315 A 87.5 87.5 0 1 0 140.06967858718917 52.50002774346447 Z"
                                                fill="rgba(26,26,132,1)"
                                            ></path>
                                            <g opacity="0.9999998780673678">
                                                <path
                                                    stroke-width="0"
                                                    stroke="#0C1013"
                                                    fill="#3260ED"
                                                    data-order="0"
                                                    d="M 140 10 A 130 130 0 0 1 245.17220048755775 63.587905115715074 L 218.8791503656683 82.6909288367863 A 97.5 97.5 0 0 0 140 42.5 Z"
                                                ></path>
                                                <path
                                                    stroke-width="0"
                                                    stroke="#0C1013"
                                                    fill="#8CA8FF"
                                                    data-order="1"
                                                    d="M 245.17220048755775 63.587905115715074 A 130 130 0 0 1 263.6373409629791 99.8277717869168 L 232.72800572223437 109.8708288401876 A 97.5 97.5 0 0 0 218.8791503656683 82.6909288367863 Z"
                                                ></path>
                                                <path
                                                    stroke-width="0"
                                                    stroke="#0C1013"
                                                    fill="#B6BD00"
                                                    data-order="2"
                                                    d="M 263.6373409629791 99.8277717869168 A 130 130 0 0 1 34.827828783070444 216.41213517182237 L 61.12087158730283 197.30910137886679 A 97.5 97.5 0 0 0 232.72800572223437 109.8708288401876 Z"
                                                ></path>
                                                <path
                                                    stroke-width="0"
                                                    stroke="#0C1013"
                                                    fill="#D65800"
                                                    data-order="3"
                                                    d="M 34.827828783070444 216.41213517182237 A 130 130 0 0 1 34.827740971232245 63.587985690823615 L 61.12080572842417 82.6909892681177 A 97.5 97.5 0 0 0 61.12087158730283 197.30910137886679 Z"
                                                ></path>
                                                <path
                                                    stroke-width="0"
                                                    stroke="#0C1013"
                                                    fill="#FFB755"
                                                    data-order="4"
                                                    d="M 34.827740971232245 63.587985690823615 A 130 130 0 0 1 139.99990040370795 10.000000000038142 L 139.99992530278098 42.50000000002861 A 97.5 97.5 0 0 0 61.12080572842417 82.6909892681177 Z"
                                                ></path>
                                            </g>
                                        </svg>
                                        <div
                                            class="doughnutSummary"
                                            style={{
                                                width: "130px",
                                                height: "130px",
                                                marginLeft: "-65px",
                                                marginTop: "-65px",
                                            }}
                                        >
                                            <p class="doughnutSummaryTitle">
                                                Total
                                            </p>
                                            <p
                                                class="doughnutSummaryNumber"
                                                style={{ opacity: 1 }}
                                            >
                                                100.0
                                            </p>
                                        </div>
                                    </div>
                                    <div class="content dist-content">
                                        <ul class="content-list list-unstyled remove-space--bottom">
                                            <li class="text-color--300 font-w--500 d-flex align-items-center justify-content-between mb-1">
                                                <div class="d-flex align-items-center">
                                                    <span
                                                        class="oval--md mr-1 jsElement"
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(50,96,237)",
                                                        }}
                                                    ></span>
                                                    <span class="content-list__name">
                                                        Pre-sale
                                                    </span>
                                                </div>
                                                <span class="content-list__value">
                                                    15%
                                                </span>
                                            </li>
                                            <li class="text-color--300 font-w--500 d-flex align-items-center justify-content-between mb-1">
                                                <div class="d-flex align-items-center">
                                                    <span
                                                        class="oval--md mr-1 jsElement"
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(140,168,255)",
                                                        }}
                                                    ></span>
                                                    <span class="content-list__name">
                                                        Community Airdrop
                                                    </span>
                                                </div>
                                                <span class="content-list__value">
                                                    5%
                                                </span>
                                            </li>
                                            <li class="text-color--300 font-w--500 d-flex align-items-center justify-content-between mb-1">
                                                <div class="d-flex align-items-center">
                                                    <span
                                                        class="oval--md mr-1 jsElement"
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(182,189,0)",
                                                        }}
                                                    ></span>
                                                    <span class="content-list__name">
                                                        Farm, Staking, In-Game
                                                        Rewards
                                                    </span>
                                                </div>
                                                <span class="content-list__value">
                                                    45%
                                                </span>
                                            </li>
                                            <li class="text-color--300 font-w--500 d-flex align-items-center justify-content-between mb-1">
                                                <div class="d-flex align-items-center">
                                                    <span
                                                        class="oval--md mr-1 jsElement"
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(214,88,0)",
                                                        }}
                                                    ></span>
                                                    <span class="content-list__name">
                                                        Project Partners
                                                    </span>
                                                </div>
                                                <span class="content-list__value">
                                                    20%
                                                </span>
                                            </li>
                                            <li class="text-color--300 font-w--500 d-flex align-items-center justify-content-between mb-1">
                                                <div class="d-flex align-items-center">
                                                    <span
                                                        class="oval--md mr-1 jsElement"
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(255,183,85)",
                                                        }}
                                                    ></span>
                                                    <span class="content-list__name">
                                                        Team and Development
                                                    </span>
                                                </div>
                                                <span class="content-list__value">
                                                    15%
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    class="space hidden roadmap roadmap--v1 section--dark bg-color--dark--1"
                    id="roadmap"
                >
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-10 col-md-8 mx-auto reveal">
                                <div class="text-center mb-sm-5 mb-lg-7">
                                    <h2 class="h3-font mb-2">
                                        Project Roadmap
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center pb-7 pb-md-0 reveal">
                            <div class="col-12 col-xl-1 mx-auto mx-lg-0 text-center">
                                <ul class="roadmap__year list-unstyled d-xl-flex flex-xl-column">
                                    <li class="h3-font font-w--700 active">
                                        2024
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-xl-9 ml-xl-auto">
                                <div class="carosuel-slider--v6 slick-initialized slick-slider slick-vertical">
                                    <but
                                        class="carosuel-arrow carosuel-arrow--vertical carosuel-arrow--prev slick-arrow slick-disabled"
                                        aria-disabled="true"
                                    >
                                        <i class="icon icon-up-arrow"></i>
                                    </but>
                                    <div
                                        class="slick-list draggable"
                                        style={{ height: "829.431px" }}
                                    >
                                        <div
                                            class="slick-track"
                                            style={{
                                                opacity: 1,
                                                height: "1106px",
                                                transform:
                                                    "translate3d(0px,0px,0px)",
                                            }}
                                        >
                                            <div
                                                class="slide mb-1 slick-slide slick-current slick-active"
                                                data-year="2024"
                                                data-slick-index="0"
                                                aria-hidden="false"
                                                style={{
                                                    width: "720px",
                                                    margin: "68px 0px",
                                                }}
                                                tabindex="0"
                                            >
                                                <div class="d-md-flex align-items-md-center">
                                                    <div class="mr-5 pr-5 mr-xl-7 pr-xl-7 mb-3 mb-md-0 position-relative">
                                                        <span class="text-color--400 font-w--600 mb-1 opacity--60">
                                                            January 2024
                                                        </span>
                                                        <h3 class="slide__title font-size--26 text-color--200 font-w--600">
                                                            Phase 1
                                                        </h3>
                                                        <span class="d-none d-md-inline-block decor decor--square decor--light"></span>
                                                    </div>
                                                    <ul class="pl-2 pl-md-0">
                                                        <li class="text-color--400">
                                                            <p class="h6-font">
                                                                Official website
                                                                launched &amp;
                                                                smart contract
                                                                deployed
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p class="h6-font">
                                                                Pre-sale &amp;
                                                                Airdrop Started
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p class="h6-font">
                                                                The first
                                                                marketing
                                                                campaign.
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p class="h6-font">
                                                                Developing the
                                                                PORTAL platform
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div
                                                class="slide mb-1 slick-slide slick-active"
                                                data-year="2024"
                                                data-slick-index="1"
                                                aria-hidden="false"
                                                style={{ width: "720px" }}
                                                tabindex="0"
                                            >
                                                <div class="d-md-flex align-items-md-center">
                                                    <div class="mr-5 pr-5 mr-xl-7 pr-xl-7 mb-3 mb-md-0 position-relative">
                                                        <span class="text-color--400 font-w--600 mb-1 opacity--60">
                                                            FEBRUARY 2024
                                                        </span>
                                                        <h3 class="slide__title font-size--26 text-color--200 font-w--600">
                                                            Phase 2
                                                        </h3>
                                                        <span class="d-none d-md-inline-block decor decor--square decor--light"></span>
                                                    </div>
                                                    <ul class="pl-2 pl-md-0">
                                                        <li class="text-color--400">
                                                            <p>
                                                                Pre-sale
                                                                completed
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p>
                                                                Airdrop
                                                                distributed
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p>
                                                                PORTAL listed on
                                                                Uniswap and
                                                                Binance
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p>
                                                                AI listed on
                                                                Coinmarketcap
                                                                and CoinGecko
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p>
                                                                The second
                                                                marketing
                                                                campaign
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div
                                                class="slide mb-1 slick-slide slick-active"
                                                data-year="2024"
                                                data-slick-index="2"
                                                aria-hidden="false"
                                                style={{
                                                    width: "720px",
                                                    margin: "68px 0px",
                                                }}
                                                tabindex="0"
                                            >
                                                <div class="d-md-flex align-items-md-center">
                                                    <div class="mr-5 pr-5 mr-xl-7 pr-xl-7 mb-3 mb-md-0 position-relative">
                                                        <span class="text-color--400 font-w--600 mb-1 opacity--60">
                                                            August 2024
                                                        </span>
                                                        <h3 class="slide__title font-size--26 text-color--200 font-w--600">
                                                            Phase 3
                                                        </h3>
                                                        <span class="d-none d-md-inline-block decor decor--square decor--light"></span>
                                                    </div>
                                                    <ul class="pl-2 pl-md-0">
                                                        <li class="text-color--400">
                                                            <p>
                                                                PORTAL listed on
                                                                Hotbit. Coinbase
                                                                exchanges
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p>
                                                                PORTAL platform
                                                                launched
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p>
                                                                Launch swap
                                                                platform,
                                                                staking program
                                                                and liquidity
                                                                pool
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p>
                                                                The third
                                                                marketing
                                                                campaign
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div
                                                class="slide mb-1 slick-slide"
                                                data-year="2024"
                                                data-slick-index="3"
                                                aria-hidden="true"
                                                style={{
                                                    width: "720px",
                                                    margin: "38px 0px",
                                                }}
                                                tabindex="-1"
                                            >
                                                <div class="d-md-flex align-items-md-center">
                                                    <div class="mr-5 pr-5 mr-xl-7 pr-xl-7 mb-3 mb-md-0 position-relative">
                                                        <span class="text-color--400 font-w--600 mb-1 opacity--60">
                                                            December 2024
                                                        </span>
                                                        <h3 class="slide__title font-size--26 text-color--200 font-w--600">
                                                            Phase 4
                                                        </h3>
                                                        <span class="d-none d-md-inline-block decor decor--square decor--light"></span>
                                                    </div>
                                                    <ul class="pl-2 pl-md-0">
                                                        <li class="text-color--400">
                                                            <p>
                                                                Launch of mobile
                                                                apps and DeFi
                                                                wallet
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p>
                                                                Launch of the
                                                                Borrow platform
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p>
                                                                Listed on the
                                                                top 10 CMC
                                                                Exchange
                                                            </p>
                                                        </li>
                                                        <li class="text-color--400">
                                                            <p>
                                                                Roadmap update
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <but
                                        class="carosuel-arrow carosuel-arrow--vertical carosuel-arrow--next slick-arrow"
                                        style={{}}
                                        aria-disabled="false"
                                    >
                                        <i class="icon icon-down-arrow"></i>
                                    </but>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    class="space--top pb-4 section--dark bg-color--dark--2"
                    id="partners"
                >
                    <div
                        class="background-holder opacity--05"
                        style={{
                            backgroundImage:
                                "url('images/layout-bg-overlay-04.png')",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <img
                            src="./assets/layout-bg-overlay-04.png"
                            alt=""
                            class="background-image-holder"
                            style={{ display: "none" }}
                        />
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-10 col-md-8 mx-auto">
                                <div class="text-center mb-5 mb-lg-6 reveal">
                                    <h2 class="h3-font mb-2">Partners</h2>
                                </div>
                            </div>
                        </div>
                        <div class="position-relative reveal">
                            <div class="vertical-border d-flex pos-abs-center h-100 w-100">
                                <span class="column-border"></span>
                                <span class="column-border"></span>
                                <span class="column-border"></span>
                            </div>
                            <div class="row horizontal-border text-center mb-lg-6 pb-lg-6 d-flex align-items-center">
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-75">
                                        <img
                                            src="./assets/partners-a16zcrypto.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-75">
                                        <img
                                            src="./assets/partners-paradigm.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-25">
                                        <img
                                            src="./assets/partners-threearrows.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-75">
                                        <img
                                            src="./assets/partners-defiance.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div class="row horizontal-border text-center mb-lg-6 pb-lg-6 d-flex align-items-center">
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-75">
                                        <img
                                            src="./assets/partners-dragonfly.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-25">
                                        <img
                                            src="./assets/partners-wintermute.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-75">
                                        <img
                                            src="./assets/partners-bcv.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-75">
                                        <img
                                            src="./assets/partners-rocktree.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div class="row horizontal-border text-center mb-lg-6 pb-lg-6 d-flex align-items-center">
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-75">
                                        <img
                                            src="./assets/partners-mgnr.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-25">
                                        <img
                                            src="./assets/partners-delphi-digital.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-75">
                                        <img
                                            src="./assets/partners-menai.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                                <div class="col-6 col-sm-3 mb-5 mb-lg-0">
                                    <span class="w-75">
                                        <img
                                            src="./assets/partners-finlink.svg"
                                            alt="partner-logo"
                                            class="img-fluid w-100"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="height-emulator d-none d-lg-block"></div>
            </main>
        </>
    );
}

export default App;
